<template>
  <div class="loginPageWrapper">
    <el-row class="contentRow">
      <el-col :span="12" class="imgCol">
        <img class="loginImg" src="@/assets/img/login.png">
      </el-col>
      <el-col :span="12" class="loginCol">
        <!-- 登录信息 -->
        <el-row class="loginInfo">
          <el-form ref="loginRef" :model="loginObj" >
            <el-form-item label="" prop="phoneNum">
              <el-input type="text" v-model.trim="loginObj.phoneNum" autocomplete="off" auto-complete="off"  placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="keyword">
              <el-input type="password" v-model.trim="loginObj.keyword" autocomplete="off" auto-complete="off" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="" prop="verifyCode">
              <el-input v-model.trim="loginObj.verifyCode" placeholder="请输入验证码"></el-input>
              <div class="verifyCodeDiv">
                
              </div>
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 登录操作 -->
        <el-row class="operateRow">
          <el-row class="registerRow">
            <a>忘记密码</a>
            <a>注册</a>
          </el-row>
          <el-row class="loginRow">
            <el-button type="primary" size="big">登录</el-button>
          </el-row>

        </el-row>
        
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name:'loginPage',
  data(){
    return {
      loginObj:{
        phoneNum: '',
        keyword: '',
        verifyCode: ''
      }
    }
  },
  mounted(){
    // this.initPage()
  },
  methods:{
    // 初始化页面数据
    initPage(){
    }
  }
}
</script>

<style lang="scss" scoped>
  .loginPageWrapper{
    padding: 0 100px;

    .contentRow{
      display: flex;
      height: 400px;
      
      .imgCol{
        text-align: right;
      }

      .loginCol{
        height: 100%;
        padding: 60px 200px;
        border: 1px solid #ccc;
        border-left: none;

        .operateRow{

          .registerRow{
            // display: flex;
            // justify-content: space-between;
            a{
              color: deepskyblue;

              &:nth-child(1){
                float: left;
              }

              &:nth-child(2){
                float: right;
              }
              
            }
            
          }

          .loginRow{
            margin-top: 30px;

            .el-button{
              padding-left: 70px;
              padding-right: 70px;
              background-color: #1b91d7;
              border-color: #1b91d7;
            }
            
          }
          
        }
        
      }

    }
    
  }
</style>